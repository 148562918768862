import { fieldValidators } from 'aca-form-generator'

export function getCookieValue(a) {
    const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)')
    return b ? b.pop() : ''
}

export function normalize(item = '') {
    if (Array.isArray(item)) {
        return item.map((str) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
        })
    }
    return item.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
}

export function formattedPhoneNumber(phone) {
    if (!phone) { return undefined }
    if (phone instanceof Object) {
        if (phone.countryCode === 'FR') {
            return phone.formatted
        } else {
            return phone.number
        }
    }
    return phone
}

export function formattedDate(date) {
    return (new Date(date)).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
}

export function findInDataLayer(key) {
    try {
        return window.dataLayer?.find((item) => !!item?.[key])?.[key]
    } catch (e) {
        console.warn(e)
        return undefined
    }
}

export function getPayload(model) {
    const subject = model['subject'] ||
        model['subject-t'] ||
        model['subject-s'] ||
        model['subject-r'] ||
        model['subject-q'] ||
        model['subject-p'] ||
        model['subject-o'] ||
        model['subject-n'] ||
        model['subject-m'] ||
        model['subject-l'] ||
        model['subject-k'] ||
        model['subject-j'] ||
        model['subject-i'] ||
        model['subject-h'] ||
        model['subject-g'] ||
        model['subject-f'] ||
        model['subject-e'] ||
        model['subject-d'] ||
        model['subject-c'] ||
        model['subject-b'] ||
        model['subject-a']

    let zip
    let city

    if (model.address) { // google address
        zip = model.address?.city?.zip
        city = model.address?.city?.name
    } else if (model.zipcode) { // zipcode component
        zip = model.zipcode?.cpCode
        city = model.zipcode?.communeLib
    }

    let cv
    if (model.cv && model.cv.length) {
        cv = model.cv[0].base64
    }

    let goals = [
        model['goal'],
        model['goal-a'],
        model['goal-b'],
        model['goal-c'],
        model['goal-d'],
        model['goal-e'],
        model['goal-f'],
        model['goal-g'],
        model['goal-h'],
        model['goal-i'],
        model['goal-j'],
        model['goal-autre'] ? 'Objectif: ' + model['goal-autre'] : undefined,
    ].filter((x) => x)

    goals = goals.length ? goals.join(' ; ') : undefined

    let levels = [
        model['level'],
        model['level-t'],
        model['level-s'],
        model['level-r'],
        model['level-q'],
        model['level-p'],
        model['level-o'],
        model['level-n'],
        model['level-m'],
        model['level-l'],
        model['level-k'],
        model['level-j'],
        model['level-i'],
        model['level-h'],
        model['level-g'],
        model['level-f'],
        model['level-e'],
        model['level-d'],
        model['level-c'],
        model['level-b'],
        model['level-a']
    ].filter((x) => x)

    levels = levels.length ? levels.join(' ; ') : undefined

    return Object.assign({}, model, {
        gtm_gclid: getCookieValue('gtm_gclid') || findInDataLayer('gclid'),
        gtm_ua_client_id: getCookieValue('gtm_ua_client_id') || findInDataLayer('uaClientId'),
        gtm_msclkid: getCookieValue('gtm_msclkid') || findInDataLayer('msclkid'),
        utmSource: getCookieValue('utmSource') || findInDataLayer('utmSource'),
        utmMedium: getCookieValue('utmMedium') || findInDataLayer('utmMedium'),
        utmCampaign: getCookieValue('utmCampaign') || findInDataLayer('utmCampaign'),
        utmContent: getCookieValue('utmContent') || findInDataLayer('utmContent'),
        leadConfirmType: model.leadConfirmType,
        newsletters: model.newsletters ? 1 : 0,
        zip: zip,
        city: city,
        finalAgency: model.finalAgency,
        phone: formattedPhoneNumber(model.phone),
        street: {
            name: model.address?.routeName,
            number: model.address?.number,
            typeCode: model.address?.routeType,
            repetitionCode: model.address?.repetition,
        },
        cv: cv,
        subject: subject === 'Autre matière' ? (model.autreMatiere || 'Autre matière') : subject,
        goal: goals,
        level: levels,
        date: formattedDate(model.date)
    })
}

export function slugify(text) {
    return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
}

export function groupBy(arr, key) {
    return arr.reduce(function(accumulator, item) {
        (accumulator[item[key]] = accumulator[item[key]] || []).push(item)
        return accumulator
    }, {})
}

const getProgressBarStepTitles = function (apiSteps) {
    const titles = {}
    Object.values(apiSteps).forEach(({ Fields }) =>  {
        const progressBar = Fields.find((field) => field.type === 'progress-bar')
        if (progressBar) {
            titles[Math.floor(progressBar.currentStep)] = progressBar.currentStepTitle
        }
    })

    return titles
}

export function transformSteps(apiSteps, context) {
    if (!apiSteps?.length) {
        return {}
    }

    const { goToStep } = context

    const steps = {}

    apiSteps.forEach((step) => {
        steps[step.Slug] = {
            schema: {
                fields: (step.Fields || []).map((field) => {
                    const validators = (field.validators || []).map((validator) => {
                        return fieldValidators[validator]
                    })

                    const newField = {
                        ...field,
                        listeners: {
                            ...field.listeners
                        },
                        id: slugify(`field-${field.model}`),
                        visible: new Function('model', field.visible),
                        validators: validators
                    }

                    if (field.loopOver) {
                        newField.loopOver = new Function('model', field.loopOver)
                    }

                    if (field.listeners && field.listeners.click) {
                        const fn = new Function('$event', 'field', 'model', 'goToStep', field.listeners.click)
                        newField.listeners.click = (...args) => {
                            return fn(...args, goToStep);
                        }
                    }

                    if (field.componentOptions) {
                        const optionsFunction = new Function(field.componentOptions)
                        newField.componentOptions = optionsFunction()
                    }

                    if (field.choices?.length) {
                        return {
                            ...newField,
                            choices: field.choices.map((choice) => {
                                if (choice.visible?.length) {
                                    return {
                                        ...choice,
                                        id: slugify(`option-${field.model}-${choice.value}`),
                                        visible: new Function('model', choice.visible),
                                    }
                                } else {
                                    return {
                                        ...choice,
                                        id: slugify(`option-${field.model}-${choice.value}`),
                                        visible: new Function('model', 'return true'),
                                    }
                                }
                            })
                        }
                    }

                    if (field.type === 'google-address') {
                        if (field.placeTypes && field.placeTypes.length) {
                            newField.placeTypes = JSON.parse(field.placeTypes)
                        }
                        if (field.restrictions && field.restrictions.length) {
                            newField.restrictions = JSON.parse(field.restrictions)
                        }
                    }

                    if (field.type === 'zipcode') {
                        return {
                            ...newField,
                            endpoint: 'https://rawp-api.domiagroup.com/v1/cities?input=',
                            headers: {}
                        }
                    }

                    if (field.type === 'progress-bar') {
                        return {
                            ...newField,
                            progressBarStepTitles: getProgressBarStepTitles(apiSteps)
                        }
                    }


                    return newField
                })
            },
            classname: step.classname,
            continueTo: new Function('model', 'fields', step.continueTo),
        }
    })

    return steps
}


export function timeWithOffset(offset) {
    const d = new Date()
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000)
    return new Date(utc + (3600000 * offset))
}
