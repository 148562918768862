import Handlebars from 'handlebars'

function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

Handlebars.registerHelper('ternary', function(test, yes, no) {
    return test ? yes : no
})

Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
        case '==':
            return (v1 == v2)
        case '===':
            return (v1 === v2)
        case '!=':
            return (v1 != v2)
        case '!==':
            return (v1 !== v2)
        case '<':
            return (v1 < v2)
        case '<=':
            return (v1 <= v2)
        case '>':
            return (v1 > v2)
        case '>=':
            return (v1 >= v2)
        case '&&':
            return (v1 && v2)
        case '||':
            return (v1 || v2)
        default:
            return options.inverse(this)
    }
})

Handlebars.registerHelper('ifFalsy', function (test) {
    if (test) {
        return false
    } else {
        return true
    }
})

Handlebars.registerHelper('ifTruthy', function (test) {
    if (test) {
        return true
    } else {
        return false
    }
})

Handlebars.registerHelper('mask', function (value, pattern) {
    if (!value) { return '' }
    if (!pattern) { return value }

    let i = 0
    const ret = value.toString();
    return pattern.replace(/#/g, () => ret[i++]);
})

// {{aLe model.zipcode.communeLib}}
Handlebars.registerHelper('aLe', function (value) {
    if (!value) {
        return ''
    }

    if (value.startsWith('Le ')) {
        return `au ${value.substring(3)}`
    } else {
        return `à ${value}`
    }
})

// {{deLe model.zipcode.communeLib}}
Handlebars.registerHelper('deLe', function (value) {
    if (!value) {
        return ''
    }

    function startsWithVowel(str) {
        const vowels = ['a', 'e', 'i', 'o', 'u']
        return vowels.includes(str[0].toLowerCase())
    }

    if (startsWithVowel(value)) {
        return `d'${value.substring(3)}`
    } else if (value.startsWith('Le ')) {
        return `du ${value.substring(3)}`
    } else {
        return `de ${value}`
    }
})

// {{date model.date}}
Handlebars.registerHelper('date', function (date) {
    if (!isValidDate((new Date(date)))) {
        return ''
    }

    return (new Date(date)).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
})

export default Handlebars