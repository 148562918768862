<script>
    import FormScreen from '@/screens/FormScreen.vue'

    export default {
        name: 'app',

        components: {
            FormScreen
        },

        props: {
            landingName: {
                type: String,
                default() {
                    const urlParams = new URLSearchParams(window.location.search)
                    const landingName = urlParams.get('forceLandingName')
                    return landingName || 'formulaire-prospect-generique'
                }
            },
            prefilledModel: {
                type: String,
            },
        },

        beforeMount() {
            console.log('v1.2')

        },

        methods: {
            handleMessage() {
                this.$emit('message', ...arguments)
            }
        }
    };
</script>
<template>
    <div id="app" class="acadomia-env">
        <FormScreen
          :landingName="landingName"
          :defaultPrefilledModel="prefilledModel"
          @message="handleMessage"/>
    </div>
</template>

<style lang="stylus">
// @import './assets/css/fonts.css'

#app {
  --light-grey-blue: #99a3d6;
  --poo-brown: #876f00;
  --golden-yellow: #eec710;
  --cobalt-blue: #011b9a;
  --rosa: #ff919c;
  --black: #000000;
  --white: #ffffff;
  --very-light-pink-two: #f2f2f2;
  --very-light-pink: #cccccc;
  --bluey-grey: #898bb4;
  --navy-blue: #00205c;
  --faded-red: #e64461;
  --gold: #d5b20e;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
    margin 0
    padding 0
    border 0
    font-size 100%
    font inherit
    vertical-align baseline
    outline 0

#app
textarea
input
    font-family 'Montserrat'
    font-weight 400
    color var(--black)

textarea:invalid
input:invalid
select:invalid
    box-shadow none


</style>
