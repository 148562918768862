<script>

import {
    BaseMultiSelect,
    IconCross,
} from 'aca-design'

function getYYYYMMDD(date) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000))
    return date.toISOString().split('T')[0]
}

function getDDMM(date) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000))

    var options = { day: '2-digit', month: '2-digit' }
    var dateFormatter = new Intl.DateTimeFormat('fr-FR', options)
    var formattedDate = dateFormatter.format(date)

    return formattedDate
}

function getDDMMYYYY(date) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000))

    var options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    var dateFormatter = new Intl.DateTimeFormat('fr-FR', options)
    var formattedDate = dateFormatter.format(date)

    return formattedDate
}

const instacoursTimes = [
    [1000, 1950], // sunday
    [1700, 2050], // monday
    [1700, 2050],
    [1600, 2050],
    [1700, 2050],
    [1700, 2050],
    [1000, 1450]
]

export default {
    name: 'InstacoursDateTime',

    components: {
        BaseMultiSelect,
        IconCross
    },

    props: {
        field: {
            type: Object,
            required: true,
        },

        value: {
            type: Object,
            default() { return {} }
        },
    },

    data () {
        return {
            dateFormatter: new Intl.DateTimeFormat('fr-FR', { dateStyle: 'full', timeZone: 'Europe/Paris' })
        }
    },

    computed: {
        instacoursDateChoices() {
            const choices = []

            const todayDay = (new Date()).getDay()
            const formatterHours = new Intl.DateTimeFormat([], { timeZone: 'Europe/Paris', hour: '2-digit'})
            const todayHours = parseInt(formatterHours.format(new Date()))

            const holydays = [
                '01/01', '01/04', '01/05',
                '08/05', '14/07', '15/08',
                '01/11', '11/11', '25/12',

                // Lundi de Pentecôte
                '20/05/2024',
                '09/06/2025',
                '25/05/2026',
                '17/05/2027',
                '05/06/2028',
                '21/05/2029',
                '10/06/2030',
                '02/06/2030',

                // Jeudi de l'Ascension
                '09/05/2024',
                '29/05/2025',
                '14/05/2026',
                '06/05/2027',
                '25/05/2028',
                '10/05/2029',
                '30/05/2030',
                '22/05/2031',

            ]

            for (var i = 0; i <= 15; i++) {

                const date = new Date()
                date.setDate(date.getDate() + i)

                if (i === 0) {
                    const timeChoices = this.getTimeChoices(getYYYYMMDD(date))
                    if (!timeChoices?.length) {
                        // We can't book for today since there are no time slots available
                        continue
                    }
                }

                if (i === 0 && todayDay === 0) { // We are sunday
                    // We can't book for today
                    continue
                }

                if ([0, 1].includes(i) && todayDay === 6 && todayHours >= 16) { // We are saturday and it's after 16h
                    // We can't book for today (saturday) nor tomorrow (sunday)
                    continue
                }

                if (i === 0 && todayHours >= 18) { // We can't book for the same day after 18h
                    continue
                }

                if (holydays.includes(getDDMM(date)) || holydays.includes(getDDMMYYYY(date))) {
                    // We can't book during holiday
                    continue
                }

                let name = this.dateFormatter.format(date)

                if (i === 0) {
                    name = `Aujourd'hui, ${name}`
                }
                if (i === 1) {
                    name = `Demain, ${name}`
                }
                choices.push({
                    name: name,
                    value: getYYYYMMDD(date)
                })
            }
            return choices
        },

        instacoursTimeChoices() {
            if (this.value?.date) {
                return this.getTimeChoices(this.value.date)
            }
            return []
        }
    },

    methods: {
        handleDateChange(date) {
            const timeChoices = this.getTimeChoices(date.value)

            const foundChoice = timeChoices.find((choice) => {
                return choice.value === this.value.time
            })

            this.$emit('change', {
                ...this.value,
                date: date.value,
                time: foundChoice ? foundChoice.value : undefined
            })
        },

        handleTimeChange(time) {
            this.$emit('change', {
                ...this.value,
                time: time.value
            })
        },

        getTimeChoices(d) {
            const date = d ? new Date(d) : new Date()
            const day = date.getDay()

            const choices = []
            let [min, max] = instacoursTimes[day]

            const isToday = d === getYYYYMMDD(new Date())

            if (isToday) {
                // remove past time slots
                const formatterHours = new Intl.DateTimeFormat([], { timeZone: 'Europe/Paris', hour: '2-digit'})
                const franceHours = parseInt(formatterHours.format(new Date()))

                const formatterMinutes = new Intl.DateTimeFormat([], { timeZone: 'Europe/Paris', minute: '2-digit'})
                const franceMinutes = parseInt(formatterMinutes.format(new Date()))

                // const franceHours = 16
                // const franceMinutes = 45

                const offset = franceMinutes > 30 ? 2 : 1.5

                min = Math.max((franceHours + offset) * 100, min)
            }

            for (var i = min; i <= max; i = i + 50) {
                const hour = i.toString().substr(0, 2)
                const minute = i.toString().substr(2, 2)
                choices.push({
                    name: `${hour}h${minute === '50' ? '30' : minute}`,
                    value: `${hour}h${minute === '50' ? '30' : minute}`
                })
            }

            return choices
        },

        handleResetDateClick() {
            this.$emit('change', {
                ...this.value,
                date: null,
                time: null
            })
        },

        handleResetTimeClick() {
            this.$emit('change', {
                ...this.value,
                time: null
            })
        }
    },
}
</script>

<template>
    <div class="instacours-date-time">
        <BaseMultiSelect
          style="margin-bottom: 20px;"
          :value="value.date"
          track-by="value"
          label="name"
          :options="instacoursDateChoices"
          :searchable="false"
          :closeOnSelect="true"
          :showLabels="false"
          :hideSelected="false"
          :multiple="false"
          placeholder="Sélectionner"
          @valueChange="($event) => handleDateChange($event)">
            <span slot="label">Date du cours</span>
            <template slot="singleLabel" slot-scope="{ option }">
                <span style="margin-top:3px; display:inline-block;">
                    {{ instacoursDateChoices.find(({ value}) => value === option).name }}
                </span>
            </template>
            <template slot="option" slot-scope="{ option }">
                <div class="flex items-center justify-between">
                    {{ option.name }}
                </div>
            </template>
            <div slot="after"
              @click="handleResetDateClick"
              :class="{ invisible: !value || !value.date }"
              class="ml-4 cursor-pointer">
                <IconCross style="width: 12px;" />
            </div>
        </BaseMultiSelect>

        <BaseMultiSelect :key="JSON.stringify(value)"
          :value="value.time"
          track-by="value"
          label="name"
          :options="instacoursTimeChoices"
          :searchable="false"
          :closeOnSelect="true"
          :showLabels="false"
          :hideSelected="false"
          :multiple="false"
          :disabled="!value.date"
          placeholder="Sélectionner"
          @valueChange="($event) => handleTimeChange($event)">
            <span slot="label">
                Choisir l'heure
                <template v-if="value.date">
                    pour {{ dateFormatter.format(new Date(value.date)) }}
                </template>
            </span>
            <template slot="singleLabel" slot-scope="{ option }">
                <span style="margin-top:3px; display:inline-block;">
                    {{ instacoursTimeChoices.find(({ value}) => value === option).name }}
                </span>
            </template>
            <template slot="option" slot-scope="{ option }">
                <div class="flex items-center justify-between">
                    {{ option.name }}
                </div>
            </template>
            <div slot="after"
              @click="handleResetTimeClick"
              :class="{ invisible: !value || !value.time }"
              class="ml-4 cursor-pointer">
                <IconCross style="width: 12px;" />
            </div>
        </BaseMultiSelect>
    </div>
</template>

<style lang="stylus">
    .instacours-date-time
        .base-multi-select
            // &.base-multi-select:not(.has-value) .after
            //     display flex

            .after
                margin-left 16px
                cursor pointer

                // .invisible
                //     visibility hidden
</style>